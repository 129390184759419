import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import Loader from 'react-loader-spinner'

const axios = require('axios');

class App extends Component{

    constructor(){
        super();
        this.state = {
            readymades: "",
            order: false,
            name: "",
            lastname: "",
            email: "",
            phone: "",
            text: "",
            name2: "",
            lastname2: "",
            email2:"",
            phone2: "",
            gdpr: false,
            gdpr2: "",
            ordering: 0,
            selected: "a",
        }
        this.order = this.order.bind(this);
        this.handleText=this.handleText.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.handleGdpr = this.handleGdpr.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    goBack = () => {
        this.setState({
            order: false,
        })
    }
    componentDidMount(){

        axios.get('https://mojekonto.sk/api/ready-made/all').then(function (response) {
            this.setState({
                readymades: response.data,
            })
        }.bind(this));

    }

    reset = () => {
        this.setState({
            order: false,
            name: "",
            lastname: "",
            email: "",
            phone: "",
            text: "",
            name2: "",
            lastname2: "",
            email2:"",
            phone2: "",
            gdpr: false,
            gdpr2: "",
            ordering: 0,
            selected: "a",
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.state.name == ""){
            this.setState({
                name2: "Toto pole je povinné!",
            });
            event.preventDefault();

        }
        else{
            this.setState({
                name2: "",
            });
            event.preventDefault();

        }

        if(this.state.lastname == ""){
            this.setState({
                lastname2: "Toto pole je povinné!",
            });
            event.preventDefault();

        }
        else{
            this.setState({
                lastname2: "",
            });
            event.preventDefault();

        }

        if(this.state.email == ""){
            this.setState({
                email2: "Toto pole je povinné!",
            });
            event.preventDefault();

        }
        else{
            this.setState({
                email2: "",
            });
            event.preventDefault();

        }

        if(this.state.phone == ""){
            this.setState({
                phone2: "Toto pole je povinné!",
            });
            event.preventDefault();

        }
        else{
            this.setState({
                phone2: "",
            });
            event.preventDefault();

        }

        if(this.state.gdpr == false){
            this.setState({
                gdpr2: "Toto pole je povinné!",
            });
            event.preventDefault();

        }
        else{
            this.setState({
                gdpr2: "",
            });
            event.preventDefault();

        }
        if(this.state.name != "" && this.state.lastname != "" && this.state.email != "" && this.state.phone != "" && this.state.gdpr == true){
            var datas = {
                name: this.state.name,
                lastname: this.state.lastname,
                email: this.state.email,
                phone: this.state.phone,
                text: this.state.text,
                id: this.state.selected.id,
            }
            console.log(datas);
            this.setState({
                ordering: 1,
            })
            datas = JSON.stringify(datas);
            var url = "https://mojekonto.sk/api/readymade/store";
            axios.post(url, datas).then(function (response) {

                this.setState({
                    ordering: 2,
                })
            }.bind(this));
        }
        else{
            event.preventDefault();
            return;
        }


    }

    handleGdpr = () => {
        this.setState({
            gdpr: !this.state.gdpr,
        })
    }
    handleLastname = (event) => {
        this.setState({
            lastname: event.target.value,
        })

    }
    handleName = (event) => {
        this.setState({
            name: event.target.value,
        })

    }
    handlePhone = (event) =>{
        this.setState({
            phone: event.target.value,
        })

    }
    handleEmail = (event) =>{
        this.setState({
            email: event.target.value,
        })

    }
    handleText = (event) => {
        this.setState({
            text: event.target.value,
        })
    }

    order = (item) => {
        this.setState({
            order: true,
            selected: item,
        })
    }

      render() {

        if(Array.isArray(this.state.readymades) && this.state.order == false) {
            return (
                <div className="app-react">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ondeskt">
                                <h2 className="centerize">Ready made</h2><br/><br/>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>NÁZOV FIRMY</th>
                                        <th>IČO</th>
                                        <th>SÍDLO</th>
                                        <th>ZÁKLADNÉ IMANIE</th>
                                        <th>REGISTRÁCIA</th>
                                        <th>STAV</th>
                                        <th>CENA</th>
                                        <th>REZERVOVAŤ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.readymades.map((item, index) => (
                                        <tr>
                                            <td>{item.nazov}</td>
                                            <td>{item.ico}</td>
                                            <td>{item.adresa}</td>
                                            <td>{item.zakladne_imanie} €</td>
                                            <td>{item.datum_zapisu}</td>
                                            <td>{item.stav}</td>
                                            <td>{item.cena}  €</td>
                                            <td><a onClick={()=>this.order(item)} className="btn btn-primary">Rezervovať</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 onmobile">
                                <h2 className="centerize">Ready made</h2><br/><br/>

                                    {this.state.readymades.map((item, index) => (
                                        <div className="rmade">
                                            <strong>{item.nazov}</strong><br/>
                                            <strong>IČO: </strong>{item.ico}<br/>
                                           {item.adresa}<br/>
                                            <strong>Základné imanie: </strong>{item.zakladne_imanie} €<br/>
                                            <strong>Dátum zápisu: </strong>{item.datum_zapisu}<br/>
                                            <span className="stav">{item.stav}</span>
                                            <span className="cena">{item.cena}  €</span>
                                            <a onClick={()=>this.order(item)} className="btn btn-primary">Rezervovať</a>
                                        </div>
                                    ))}

                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else if(this.state.order==true){
            return(
                <div className="app-react">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="centerize">Ready made</h2><br/>
                                <h4 className="centerize">Nezáväzná objednávka - {this.state.selected.nazov}</h4><br/><br/>
                                {this.state.ordering == 0 ?
                                    <form onSubmit={this.handleSubmit}>
                                    <div className="form-row">
                                    <div className="col-md-6">
                                    <label>Meno: *</label>
                                    <input onChange={this.handleName} placeholder="Meno" className="form-control" type="text" value={this.state.name} />
                                    <span className="error">{this.state.name2}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <label>Priezvisko: *</label>
                                    <input onChange={this.handleLastname} placeholder="Priezvisko" className="form-control" type="text" value={this.state.lastname} />
                                    <span className="error">{this.state.lastname2}</span>
                                    </div>

                                    </div>
                                    <div className="form-row">
                                    <div className="col-md-6">
                                    <label>E-Mail: *</label>
                                    <input onChange={this.handleEmail} placeholder="E-Mail" className="form-control" type="text" value={this.state.email} />
                                    <span className="error">{this.state.email2}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <label>Tel. číslo: *</label>
                                    <input onChange={this.handlePhone} placeholder="Tel. číslo" className="form-control" type="text" value={this.state.phone} />
                                    <span className="error">{this.state.phone2}</span>
                                    </div>

                                    </div>
                                    <div className="form-row">
                                    <div className="col-md-12">
                                    <label>Poznámka: </label>
                                    <textarea placeholder="Vaša poznámka" className="form-control" value={this.state.text} onChange={this.handleText}></textarea>
                                    </div>

                                    </div>
                                    <div className="form-row">
                                    <div className="col-md-12 odbot">
                                    <input checked={this.state.gdpr == true} type="checkbox" onChange={this.handleGdpr}/> Súhlasím so spracovaním osobných údajov v súlade s nariadením GDPR o ochrane osobných údajov (<a target="_blank" href="https://www.firmaprefirmy.sk/ochrana-sukromia">Viac informácií.</a>)<br/>
                                    <span className="error"> {this.state.gdpr2} </span><br/>
                                    <br/>

                                    <div className="centerize">
                                    <a className="back" onClick={this.goBack}>SPÄŤ</a>
                                    <input type="submit" value="Odoslať" className="btn btn-primary" />
                                    </div>
                                    </div>
                                    </div>
                                    </form>
                                : null} {this.state.ordering == 1 ?  <div className="centerize"><Loader
                                    type="Oval"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}


                                    /></div>: null}

                                {this.state.ordering == 2 ?
                                    <div className="centerize">
                                        <br/><h4 className="thanyou">Ďakujeme za nezáväznú objednávku</h4>
                                        <div className="centerize">
                                            <br/><br/>
                                            <a onClick={this.reset} className="btn btn-primary">Znova objednať</a>
                                        </div>
                                    </div>

                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        else{
            return(
                <div className="center app-react">
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}


                    />
                </div>
            );
        }
      }
}

export default App;
